.alertify .ajs-dimmer {
  z-index: 1981;
  opacity: .5;
  background-color: #252525;
  margin: 0;
  padding: 0;
  position: fixed;
  inset: 0;
}

.alertify .ajs-modal {
  z-index: 1981;
  padding: 0;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.alertify .ajs-dialog {
  background-color: #fff;
  outline: 0;
  max-width: 500px;
  min-height: 110px;
  margin: 5% auto;
  padding: 24px 24px 0;
  position: relative;
}

.alertify .ajs-dialog.ajs-capture:before {
  content: "";
  z-index: 1;
  display: block;
  position: absolute;
  inset: 0;
}

.alertify .ajs-reset {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  display: inline !important;
  position: absolute !important;
}

.alertify .ajs-commands {
  z-index: 2;
  margin: -14px 24px 0 0;
  position: absolute;
  right: 4px;
}

.alertify .ajs-commands button {
  cursor: pointer;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  padding: 10px;
  display: none;
}

.alertify .ajs-commands button.ajs-close {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAh0lEQVQYlY2QsQ0EIQwEB9cBAR1CJUaI/gigDnwR6NBL/7/xWLNrZ2b8EwGotVpr7eOitWa1VjugiNB7R1UPrKrWe0dEAHBbXUqxMQbeewDmnHjvyTm7C3zDwAUd9c63YQdUVdu6EAJzzquz7HXvTiklt+H9DQFYaxFjvDqllFyMkbXWvfpXHjJrWFgdBq/hAAAAAElFTkSuQmCC");
}

.alertify .ajs-commands button.ajs-maximize {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAOUlEQVQYlWP8//8/AzGAhYGBgaG4uBiv6t7eXkYmooxjYGAgWiELsvHYFMCcRX2rSXcjoSBiJDbAAeD+EGu+8BZcAAAAAElFTkSuQmCC");
}

.alertify .ajs-header {
  background-color: #fff;
  margin: -24px -24px 0;
  padding: 16px 24px;
}

.alertify .ajs-body {
  min-height: 56px;
}

.alertify .ajs-body .ajs-content {
  padding: 16px 24px 16px 16px;
}

.alertify .ajs-footer {
  background-color: #fff;
  min-height: 43px;
  margin-left: -24px;
  margin-right: -24px;
  padding: 4px;
}

.alertify .ajs-footer .ajs-buttons.ajs-primary {
  text-align: right;
}

.alertify .ajs-footer .ajs-buttons.ajs-primary .ajs-button {
  margin: 4px;
}

.alertify .ajs-footer .ajs-buttons.ajs-auxiliary {
  float: left;
  clear: none;
  text-align: left;
}

.alertify .ajs-footer .ajs-buttons.ajs-auxiliary .ajs-button {
  margin: 4px;
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
  min-width: 88px;
  min-height: 35px;
}

.alertify .ajs-handle {
  z-index: 1;
  cursor: se-resize;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMS8xNEDQYmMAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAQ0lEQVQYlaXNMQoAIAxD0dT7H657l0KX3iJuUlBUNOsPPCGJm7VDp6ryeMxMuDsAQH7owW3pyn3RS26iKxERMLN3ugOaAkaL3sWVigAAAABJRU5ErkJggg==");
  width: 10px;
  height: 10px;
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: scaleX(1);
}

.alertify.ajs-no-overflow .ajs-body .ajs-content {
  overflow: hidden !important;
}

.alertify.ajs-no-padding.ajs-maximized .ajs-body .ajs-content {
  padding: 0;
  left: 0;
  right: 0;
}

.alertify.ajs-no-padding:not(.ajs-maximized) .ajs-body {
  margin-left: -24px;
  margin-right: -24px;
}

.alertify.ajs-no-padding:not(.ajs-maximized) .ajs-body .ajs-content {
  padding: 0;
}

.alertify.ajs-no-padding.ajs-resizable .ajs-body .ajs-content {
  left: 0;
  right: 0;
}

.alertify.ajs-maximizable .ajs-commands button.ajs-maximize, .alertify.ajs-maximizable .ajs-commands button.ajs-restore, .alertify.ajs-closable .ajs-commands button.ajs-close {
  display: inline-block;
}

.alertify.ajs-maximized .ajs-dialog {
  width: 100% !important;
  max-width: none !important;
  height: 100% !important;
  margin: 0 auto !important;
  top: 0 !important;
  left: 0 !important;
}

.alertify.ajs-maximized.ajs-modeless .ajs-modal {
  min-height: 100% !important;
  max-height: none !important;
  margin: 0 !important;
  position: fixed !important;
}

.alertify.ajs-maximized .ajs-commands button.ajs-maximize {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAASklEQVQYlZWQ0QkAMQhDtXRincOZX78KVtrDCwgqJNEoIB3MPLj7lRUROlpyVXGzby6zWuY+kz6tj5sBMTMAyVV3/595RbOh3cAXsww1raeiOcoAAAAASUVORK5CYII=");
}

.alertify.ajs-maximized .ajs-dialog, .alertify.ajs-resizable .ajs-dialog {
  padding: 0;
}

.alertify.ajs-maximized .ajs-commands, .alertify.ajs-resizable .ajs-commands {
  margin: 14px 24px 0 0;
}

.alertify.ajs-maximized .ajs-header, .alertify.ajs-resizable .ajs-header {
  margin: 0;
  padding: 16px 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.alertify.ajs-maximized .ajs-body, .alertify.ajs-resizable .ajs-body {
  min-height: 224px;
  display: inline-block;
}

.alertify.ajs-maximized .ajs-body .ajs-content, .alertify.ajs-resizable .ajs-body .ajs-content {
  position: absolute;
  inset: 50px 24px;
  overflow: auto;
}

.alertify.ajs-maximized .ajs-footer, .alertify.ajs-resizable .ajs-footer {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.alertify.ajs-resizable:not(.ajs-maximized) .ajs-dialog {
  min-width: 548px;
}

.alertify.ajs-resizable:not(.ajs-maximized) .ajs-handle {
  display: block;
}

.alertify.ajs-movable:not(.ajs-maximized) .ajs-header {
  cursor: move;
}

.alertify.ajs-modeless .ajs-dimmer, .alertify.ajs-modeless .ajs-reset {
  display: none;
}

.alertify.ajs-modeless .ajs-modal {
  max-width: none;
  max-height: 0;
  overflow: visible;
}

.alertify.ajs-modeless.ajs-pinnable .ajs-commands button.ajs-pin {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAQklEQVQYlcWPMQ4AIAwCqU9u38GbcbHRWN1MvKQDhQFMEpKImGJA0gCgnYw0V0rwxseg5erT4oSkQVI5d9f+e9+xA0NbLpWfitPXAAAAAElFTkSuQmCC");
  display: inline-block;
}

.alertify.ajs-modeless.ajs-unpinned .ajs-modal {
  position: absolute;
}

.alertify.ajs-modeless.ajs-unpinned .ajs-commands button.ajs-pin {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAO0lEQVQYlWP8//8/AzGAiShV6AqLi4txGs+CLoBLMYbC3t5eRmyaWfBZhwwYkX2NTxPRvibKjRhW4wMAhxkYGbLu3pEAAAAASUVORK5CYII=");
}

.alertify.ajs-modeless:not(.ajs-unpinned) .ajs-body {
  max-height: 500px;
  overflow: auto;
}

.alertify.ajs-basic .ajs-header {
  opacity: 0;
}

.alertify.ajs-basic .ajs-footer {
  visibility: hidden;
}

.alertify.ajs-frameless .ajs-header {
  opacity: 0;
  z-index: 1;
  min-height: 60px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.alertify.ajs-frameless .ajs-footer {
  display: none;
}

.alertify.ajs-frameless .ajs-body .ajs-content {
  position: absolute;
  inset: 0;
}

.alertify.ajs-frameless:not(.ajs-resizable) .ajs-dialog {
  padding-top: 0;
}

.alertify.ajs-frameless:not(.ajs-resizable) .ajs-dialog .ajs-commands {
  margin-top: 0;
}

.ajs-no-overflow {
  outline: 0;
  overflow: hidden !important;
}

.ajs-no-overflow.ajs-fixed {
  position: fixed;
  inset: 0;
  overflow-y: scroll !important;
}

.ajs-no-selection, .ajs-no-selection * {
  user-select: none;
}

@media screen and (width <= 568px) {
  .alertify .ajs-dialog {
    min-width: 150px;
  }

  .alertify:not(.ajs-maximized) .ajs-modal {
    padding: 0 5%;
  }

  .alertify:not(.ajs-maximized).ajs-resizable .ajs-dialog {
    min-width: initial;
    min-width: auto;
  }
}

@-moz-document url-prefix() {
  .alertify button:focus {
    outline: 1px dotted #3593d2;
  }
}

.alertify .ajs-dimmer, .alertify .ajs-modal {
  transition-property: opacity, visibility;
  transition-duration: .25s;
  transition-timing-function: linear;
  transform: translate3d(0, 0, 0);
}

.alertify.ajs-hidden .ajs-dimmer, .alertify.ajs-hidden .ajs-modal {
  visibility: hidden;
  opacity: 0;
}

.alertify.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-duration: .5s;
}

.alertify.ajs-out.ajs-hidden .ajs-dialog {
  animation-duration: .25s;
}

.alertify .ajs-dialog.ajs-shake {
  animation-name: ajs-shake;
  animation-duration: .1s;
  animation-fill-mode: both;
}

@keyframes ajs-shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.alertify.ajs-slide.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-slideIn;
  animation-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

.alertify.ajs-slide.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-slideOut;
  animation-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

.alertify.ajs-zoom.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-zoomIn;
}

.alertify.ajs-zoom.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-zoomOut;
}

.alertify.ajs-fade.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-fadeIn;
}

.alertify.ajs-fade.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-fadeOut;
}

.alertify.ajs-pulse.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-pulseIn;
}

.alertify.ajs-pulse.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-pulseOut;
}

.alertify.ajs-flipx.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-flipInX;
}

.alertify.ajs-flipx.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-flipOutX;
}

.alertify.ajs-flipy.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-flipInY;
}

.alertify.ajs-flipy.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-flipOutY;
}

@keyframes ajs-pulseIn {
  0%, 100%, 20%, 40%, 60%, 80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes ajs-pulseOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  100% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

@keyframes ajs-zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.25, .25, .25);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes ajs-zoomOut {
  0% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  100% {
    opacity: 0;
    transform: scale3d(.25, .25, .25);
  }
}

@keyframes ajs-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ajs-fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ajs-flipInX {
  0% {
    opacity: 0;
    transition-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    transition-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  100% {
    transform: perspective(400px);
  }
}

@keyframes ajs-flipOutX {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  100% {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

@keyframes ajs-flipInY {
  0% {
    opacity: 0;
    transition-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    transition-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  100% {
    transform: perspective(400px);
  }
}

@keyframes ajs-flipOutY {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  100% {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

@keyframes ajs-slideIn {
  0% {
    margin-top: -100%;
  }

  100% {
    margin-top: 5%;
  }
}

@keyframes ajs-slideOut {
  0% {
    margin-top: 5%;
  }

  100% {
    margin-top: -100%;
  }
}

.alertify-notifier {
  z-index: 1982;
  width: 0;
  position: fixed;
  overflow: visible;
  transform: translate3d(0, 0, 0);
}

.alertify-notifier .ajs-message {
  opacity: 0;
  width: 260px;
  max-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: .25s;
  transition-timing-function: linear;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.alertify-notifier .ajs-message.ajs-visible {
  opacity: 1;
  max-height: 100%;
  margin-top: 10px;
  padding: 15px;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

.alertify-notifier .ajs-message.ajs-success {
  background: #5bbd72f2;
}

.alertify-notifier .ajs-message.ajs-error {
  background: #d95c5cf2;
}

.alertify-notifier .ajs-message.ajs-warning {
  background: #fcf8d7f2;
}

.alertify-notifier .ajs-message .ajs-close {
  cursor: pointer;
  background-color: #00000080;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAABGdBTUEAALGPC/xhBQAAAFBJREFUGBl1j0EKADEIA+ve/P9f9bh1hEihNBfjVCO1v7RKVqJK4h8gM5cAPR42AkQEpSXPwMTyoi13n5N9YqJehm3Fnr7nL1D0ZEbD5OubGyC7a9gx+9eNAAAAAElFTkSuQmCC");
  background-position: center;
  background-repeat: no-repeat;
  border-top-right-radius: 2px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
}

.alertify-notifier.ajs-top {
  top: 10px;
}

.alertify-notifier.ajs-bottom {
  bottom: 10px;
}

.alertify-notifier.ajs-right {
  right: 10px;
}

.alertify-notifier.ajs-right .ajs-message {
  right: -320px;
}

.alertify-notifier.ajs-right .ajs-message.ajs-visible {
  right: 290px;
}

.alertify-notifier.ajs-left {
  left: 10px;
}

.alertify-notifier.ajs-left .ajs-message {
  left: -300px;
}

.alertify-notifier.ajs-left .ajs-message.ajs-visible {
  left: 0;
}

.alertify-notifier.ajs-center {
  left: 50%;
}

.alertify-notifier.ajs-center .ajs-message {
  transform: translateX(-50%);
}

.alertify-notifier.ajs-center .ajs-message.ajs-visible {
  transition-timing-function: cubic-bezier(.57, .43, .1, .65);
  left: 50%;
}

.alertify-notifier.ajs-center.ajs-top .ajs-message {
  top: -300px;
}

.alertify-notifier.ajs-center.ajs-top .ajs-message.ajs-visible {
  top: 0;
}

.alertify-notifier.ajs-center.ajs-bottom .ajs-message {
  bottom: -300px;
}

.alertify-notifier.ajs-center.ajs-bottom .ajs-message.ajs-visible {
  bottom: 0;
}

.ajs-no-transition.alertify .ajs-dialog, .ajs-no-transition.alertify .ajs-dimmer, .ajs-no-transition.alertify .ajs-modal, .ajs-no-transition.alertify-notifier .ajs-message {
  transition: none !important;
  animation: none !important;
}

@media (prefers-reduced-motion: reduce) {
  .alertify .ajs-dialog, .alertify .ajs-dimmer, .alertify .ajs-modal, .alertify-notifier .ajs-message {
    transition: none !important;
    animation: none !important;
  }
}
/*# sourceMappingURL=index.4ca58cfd.css.map */
